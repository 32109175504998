@for $i from 2 to 100 {
  .fs-#{$i} {
    font-size: #{$i}px;
  }
  .lh-#{$i} {
    line-height: #{$i}px;
  }
}

.fs-0 {
  font-size: 0px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-bold {
  font-weight: bold;
}

.lh-0 {
  line-height: 0px;
}

.lh-normal {
  line-height: normal;
}

.text-center {
  text-align: center;
}