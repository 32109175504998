@import './responsive.scss';

$position: top, right, bottom, left;

@each $item in $position {
  .m#{str-slice($item, 0, 1)}-auto {
    margin-#{$item}: auto;
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

@for $i from 2 to 100 {
  @each $item in $position {
    .m#{str-slice($item, 0, 1)}-#{$i} {
      margin-#{$item}: #{$i}px;
    }
    .p#{str-slice($item, 0, 1)}-#{$i} {
      padding-#{$item}: #{$i}px;
    }
  }
  .padding-#{$i} {
    padding: #{$i}px;
  }
  .height-#{$i} {
    height: #{$i}px;
  }
  .width-#{$i} {
    width: #{$i}px;
  }
}

.width100 {
  width: 100%;
}

.text-ellipsis {
  white-space: nowrap; // 强制文本在一行显示
  overflow: hidden; // 隐藏溢出文本
  text-overflow: ellipsis; // 显示省略号
}