@import './styles/iconfont.scss';
@import './styles/responsive.scss';
@import './styles/layout.scss';
@import './styles/flex.scss';
@import './styles/font.scss';

body {
  --theme-bg: #f9f9f9;
  --theme-color: #121212;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: var(--theme-color);
}

:root:root {
  --adm-font-size-9: 15px;
}

:root {
  color-scheme: light dark;
}

.transition {
  transition: all 0.3s ease-in-out;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.cursor-not-allowed:hover {
  cursor: not-allowed;
}

.theme-color {
  color: var(--theme-color);
}

.theme-bg {
  background-color: var(--theme-bg);
}

.bg-fff {
  background-color: #fff;
}

.color-000 {
  color: #000;
}

.color-000-5 {
  color: rgba(0, 0, 0, 0.5);
}

.block-border {
  border: solid 1px #eee;
}

.block-border-top {
  border-top: solid 1px #eee;
}

.color-blue {
  color: #1677ff;
}

.block-border-bottom {
  border-bottom: solid 1px #eee;
}

.plus-list-no-border .adm-list-body {
  border: none !important;
}

.adm-capsule-tabs-tab {
  padding: 6px 12px !important;
}

.adm-dropdown-item {
  flex: 1 auto !important;
}

.adm-collapse-no-layout {
  .adm-list-body {
    border-bottom: none !important;
    border-top: none !important;
  }
  .adm-list-item {
    padding-left: 0px !important;
  }
}

.shop-list-form-item-no-padding {
  .adm-list-item-content-main {
    padding: 0px !important;
  }
  .adm-collapse-panel-header .adm-list-item-content-main {
    padding: 12px 0px !important;
  }
  .adm-space-vertical {
    padding: 12px 0px;
  }
}

.width1000 {
  width: 1000px;
}


@include mobile() {
  #root {
    height: 100%;
    width: 100%;
  }

  .main {
    min-height: 100vh;
    width: 100%;
    background-color: var(--theme-bg);
  }

  .mobile-hidden {
    display: none;
  }
}

@include pc() {
  #root {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
  }

  .main {
    height: 736px;
    min-width: 414px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    transform: translate(0%, 0%);
    background-color: var(--theme-bg);
  }

  .pc-hidden {
    display: none;
  }
}
