.home {
  width: 100%;
  .nav,
  .table {
    background-color: #fff;
  }
  .table {
    max-width: 100%;
    overflow: auto;
  }
  table {
    --table-color: #eee;
    width: 100%;
    border-spacing: 0px;
    border-top: 1px solid var(--table-color);
    border-left: 1px solid var(--table-color);
    border-bottom: 1px solid var(--table-color);
    thead,
    tbody,
    tr {
      width: 100%;
    }
    th,
    td {
      min-width: 20%;
      text-align: left;
      box-sizing: border-box;
      border-bottom: 1px solid var(--table-color);
      border-right: 1px solid var(--table-color);
      white-space: nowrap;
      font-size: 14px;
    }
    th {
      font-weight: bold;
      padding: 10px 8px;
    }
    td {
      padding: 8px;
    }
  }
}
