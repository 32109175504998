@mixin mobile() {
  @media (max-width: 680px) {
    @content;
  }
}

@mixin pc() {
  @media (min-width: 680px) {
    @content;
  }
}

@mixin pc-small() {
  // apple 电脑端最小834
  @media (min-width: 680px) and (max-width: 1512px) {
    @content;
  }
}

@mixin pc-large() {
  @media (min-width: 1513px) {
    @content;
  }
}